import { ProductStage, ProductType } from '../types/common';

export const removeFirstProductImage = (
  images: Shopify.ImageEdge[],
  productType: ProductType,
  productStage: ProductStage,
): Shopify.ImageEdge[] => {
  if (
    (productType !== ProductType.Hoodie && productType !== ProductType.Crewneck) ||
    productStage === ProductStage.ComingSoon
  ) {
    return images;
  }

  // Remove first image if a hoodie
  return images.slice(1);
};
